import LogoImage from "../../assets/images/logo/logo.png";

export default function Logo() {
  return (
    <div className={"flex h-1/6 p-5"}>
      <img
        className={"mx-auto object-scale-down"}
        src={LogoImage}
        alt="Logo"
        draggable={false}
      />
    </div>
  );
}
