import { IOverlay } from "../types";

export default function Overlay({ children, show }: IOverlay) {
  return (
    <div
      className={`z-50 absolute top-0 flex items-center justify-center w-full h-full bg-black bg-opacity-20 ${
        show ? "visible" : "invisible"
      }`}
    >
      {children}
    </div>
  );
}
