export const USER_REQUESTED_NAVIGATION_TO_MAIN_MENU =
  "pc_user_requested_navigation_to_main_menu";
export const USER_CONFIRMED_NAVIGATION_TO_MAIN_MENU =
  "pc_user_confirmed_navigation_to_main_menu";
export const USER_VIEWED_MISSION_INTRODUCTION =
  "pc_user_viewed_mission_introduction";
export const USER_VIEWED_MISSION_INFORMATION =
  "pc_user_viewed_mission_information";
export const USER_PRESSED_CONTINUE = "pc_user_pressed_continue";
export const USER_STARTED_MISSION = "pc_user_started_mission";
export const USER_SELECTED_ACTOR = "pc_user_selected_actor";
export const USER_DESELECTED_ACTOR = "pc_user_deselected_actor";
export const USER_HOVERED_ACTOR = "pc_user_hovered_actor";
export const USER_HOVERED_ACTOR_EXIT = "pc_user_hovered_actor_exit";
export const USER_SELECTED_CHOICE = "pc_user_selected_choice";
export const USER_DESELECTED_CHOICE = "pc_user_deselected_choice";
export const ACTOR_SELECTION_UNAVAILABLE = "pc_actor_selection_unavailable";
export const ACTOR_SELECTION_AVAILABLE = "pc_actor_selection_available";
export const UPDATE_CONTINUE_BUTTON_STATE = "pc_update_continue_button_state";
export const USER_CONFIRMATION_REQUIRED = "pc_user_confirmation_required";
export const USER_CANCELLED_SUBMISSION = "pc_user_cancelled_submission";
export const USER_SUBMITTED_ANSWER = "pc_user_submitted_answer";
export const USER_RESTARTED_MISSION = "pc_user_restarted_mission";
export const USER_STARTED_NEXT_MISSION_STEP =
  "pc_user_started_next_mission_step";
export const USER_COMPLETED_MISSION = "pc_user_completed_mission";
export const USER_STARTED_NEXT_MISSION = "pc_user_started_next_mission";
export const USER_VIEWING_INTERMEDIATE_INFORMATION =
  "pc_user_viewing_intermediate_information";
export const LOGBOOK_OVERLAY_REQUESTED = "pc_logbook_requested";
export const LOG_ITEM_SELECTED = "pc_log_item_selected";
export const USER_REQUESTED_MISSION_INFORMATION =
  "pc_user_requested_mission_information";
export const USER_CLOSED_MISSION_INFORMATION =
  "pc_user_closed_mission_information";
export const MISSION_STEP_FEASIBLITY_CALCULATED = "pc_feasibility_calculated";
