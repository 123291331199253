import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import NavigationConfirmation from "../../components/confirmation/navigation-confirmation/navigation-confirmation";
import Logo from "../../components/logo/logo";
import {
  HOME,
  MISSION_GAMEPLAY,
  MISSION_SELECTION,
  MISSION_SELECTION_BASE,
  SCENARIO_SELECTION,
} from "../../constants/routes";

export default function AppContainer() {
  const [shouldShowLogo, setShouldShowLogo] = useState(false);
  const [shouldShowDisclaimer, setShouldShowDisclaimer] = useState(false);

  const location = useLocation();

  const Home = lazy(() => import("../../pages/home/home"));
  const ScenarioSelection = lazy(
    () => import("../../pages/scenario-selection/scenario-selection")
  );
  const MissionSelection = lazy(
    () => import("../../pages/mission-selection/mission-selection")
  );
  const MissionGameplay = lazy(
    () => import("../../pages/mission-gameplay/mission-gameplay")
  );

  useEffect(() => {
    setShouldShowLogo(currentPageRequiresLogo(location.pathname));
    setShouldShowDisclaimer(location.pathname === HOME);
  }, [location]);

  function currentPageRequiresLogo(location: string) {
    location = "/" + location.split("/").slice(1)[0];

    if (location === HOME) return true;

    if (location === SCENARIO_SELECTION) return true;

    if (location === MISSION_SELECTION_BASE) return true;

    return false;
  }

  return (
    <div
      className={
        "flex flex-col h-full w-full bg-main bg-cover bg-no-repeat bg-center select-none overflow-hidden"
      }
    >
      {shouldShowLogo && <Logo />}
      {shouldShowDisclaimer && (
        <div className={"absolute top-0 p-10"}>
          <span className={"text-red-500 text-5xl uppercase"}>
            Prototype v.0.3
          </span>
        </div>
      )}
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={HOME} component={Home} />
          <Route
            exact
            path={SCENARIO_SELECTION}
            component={ScenarioSelection}
          />
          <Route exact path={MISSION_SELECTION} component={MissionSelection} />
          <Route exact path={MISSION_GAMEPLAY} component={MissionGameplay} />
        </Switch>
      </Suspense>
      <NavigationConfirmation />
    </div>
  );
}
