import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./app.css";
import AppContainer from "./containers/app-container/app-container";

export default function App() {
  return (
    <Router>
      <RecoilRoot>
        <AppContainer />
      </RecoilRoot>
    </Router>
  );
}
