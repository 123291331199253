import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { USER_REQUESTED_NAVIGATION_TO_MAIN_MENU } from "../../../events/event-types";
import { detachFromEvent, listenToEvent } from "../../../events/events";
import Overlay from "../../overlay/overlay";

export default function NavigationConfirmation() {
  const [shouldShow, setShouldShow] = useState(false);

  const history = useHistory();

  useEffect(() => {
    listenToEvent({
      eventType: USER_REQUESTED_NAVIGATION_TO_MAIN_MENU,
      listener: show,
    });

    return () => {
      detachFromEvent({
        eventType: USER_REQUESTED_NAVIGATION_TO_MAIN_MENU,
        listener: show,
      });
    };
  }, []);

  function show() {
    setShouldShow(true);
  }

  function hide() {
    setShouldShow(false);
  }

  function navigateToMainMenu() {
    history.push("/");
    hide();
  }

  return (
    <Overlay show={shouldShow}>
      <div
        className={
          "relative -top-1/4 flex flex-col item-center justify-center w-1/3 h-1/4 bg-white shadow-xl rounded-3xl"
        }
      >
        <div
          className={"flex flex-col mx-auto items-center h-2/3 w-4/5 space-y-5"}
        >
          <span className={"flex text-center xl:text-2xl 2xl:text-3xl text-lg"}>
            Are you sure you want to navigate back to the main menu?
          </span>
          <span className={"xl:text-xl 2xl:text-2xl text-lg text-pc-orange"}>
            Your progress will not be saved!
          </span>
        </div>
        <div
          className={
            "absolute transform bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 flex flex-row mx-auto h-1/4 w-5/6 text-3xl uppercase"
          }
        >
          <div
            className={
              "group flex transform hover:scale-110 hover:border-2 hover:border-solid hover:border-pc-orange hover:text-pc-orange  items-center justify-center w-1/3 cursor-pointer bg-pc-dark-blue rounded-b-xl shadow-xl"
            }
            onClick={navigateToMainMenu}
          >
            <span className={"group-hover:text-pc-orange  text-center font-bold text-white"}>Yes</span>
          </div>
          <div
            className={
              "group flex transform hover:scale-110 hover:border-2 hover:border-solid hover:border-pc-orange ml-auto mr-0 items-center justify-center w-1/3 cursor-pointer bg-pc-dark-blue rounded-b-xl shadow-xl"
            }
            onClick={hide}
          >
            <span className={"group-hover:text-pc-orange text-center font-bold text-white"}>No</span>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
