interface EventBaseProps {
  eventType: string;
}

interface EventTriggerProps extends EventBaseProps {
  data: unknown;
}

interface EventListenerProps extends EventBaseProps {
  listener: any;
}

function listenToEvent({ eventType, listener }: EventListenerProps) {
  document.addEventListener(eventType, listener);
}

function listenToEventOnce() {}

function detachFromEvent({ eventType, listener }: EventListenerProps) {
  document.removeEventListener(eventType, listener);
}

function triggerEvent({ eventType, data }: EventTriggerProps) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { listenToEvent, listenToEventOnce, detachFromEvent, triggerEvent };
